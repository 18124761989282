import styled from 'styled-components';

const EstiloHeader = styled.div`
  background: #e6f2f6 !important;
  color: #666;
  box-shadow: 0 2px 2px rgba(0,0,0,0.3);
  height: 64px;
  .logo{
    float: left;
    padding: 2px;
    margin-left: 10px;

    img{
      max-height: 60px;
    }
  }
  h3{
    text-align: center;
    margin:auto;
    font-family: 'Mulish', sans-serif;
    font-size: 30px !important;
    font-style: normal;
    color: #4e4e4e;
    padding: 10px;
  }
  ul{
    float:right;
    padding: 20px;
    margin:auto;
    li{
      display:inline;
      font-weight:700;
      font-size: 16px;
      text-transform:uppercase;
      a{
        color: #4e4e4e;
      }
    }
    li + li:before{
        content: " | ";
        padding: 0 10px;
    }
  }
`

const Styles = styled.div`
  padding: 1rem;
  font-family: 'Mulish', sans-serif;
  }
  select,input {
  outline: none;
  }
  select{
    border: 1px solid rgba(0,0,0,0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
  }
  input{
    border: 1px solid rgba(0,0,0,0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
  }
`
const EstiloTabla = styled.div`

  table{
    width: 100%
  }
  th{
    text-align: center;
    padding: 6px;
    font-weight: bold;
    background-color: #FFF;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid #ddd;
    border-bottom: 2px solid #ddd;

    span{
      margin-left:4px;
      color: #1b92c1;
    }
  }
  tr:hover{
    background-color: #D9EBF2 !important;
  }
  td{
    padding: 6px;
    margin: 0px;
    border: none;
    font-size: 12px;
  }

  p.fecha_actualizacion{
    font-size: 14px;
    text-align:right;
    color:#666;
  }
  a{
    cursor:pointer
  }
`
const EstiloFiltro = styled.div`
  select,input {
    width: 100% !important;
    height:35px;
  }
  input{
  }
  span{
    display:block;
    font-weight:bold;
  }

`
const EstiloPaginacion = styled.div`
  div{
    width: 600px;
    margin:auto;
  }
  input:{
    width: 220px;
  }
  button{
    width: 50px;
    text-align:center;
    color: rgba(0,0,0,0.6);
    background: rgba(0,0,0,0.1);
    border: 0;
    border-radius: 3px;
    margin: 2px;
  }
  button:hover{
    background-color: #777;
  color: #FFF;
  }
  button:disabled{
    opacity: .5;
    cursor: default;
  }
`

const EstiloFooter = styled.div`
  border-top: 4px solid;
  background-color: #FFF;
  text-align:center;
  font-family: 'Mulish',Tahoma,Verdana;
  font-size: 14px;
  color: #999999;
  margin-top: 30px;
  box-shadow: 0 4px 10px -1px rgba(200, 200, 200, 0.9);
  h3{
    font-size: 16px;
    margin: 2px;
    font-weight: 700;
  }
  p{
    margin: 2px;
    a{
      color: #8ed7f7;
      font-weight: 900;
    }
  }
`

const ListUL = styled.div`
  font-family: 'Mulish', sans-serif;
  padding: 20px;
  background-color: #e6f2f6;
  margin: 10px 50px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  ul{
    margin-bottom:5px;
  }
  h3{
    font-size: 18px;
    color:#333;
  }
  li{
    display:inline;
    a{
      color:#666;
      font-weight: bold;
    }
  }
  li + li::before {
      content: " - ";
  }
`

const EstiloInfo = styled.div`
margin:20px 80px;
font-family: 'Mulish', sans-serif;
h3{
  margin-top:40px;
  font-size: 18px;
  font-weight:bold;
}
`

const Green = styled.div`
  color:#398629;
}
`
const Gray = styled.div`
  color:#666;
}
`
const Black = styled.div`
  color:#010101;
}
`
const Blue = styled.div`
  color:#1b92c1;
}
`

export { EstiloHeader, Styles, EstiloTabla, EstiloFiltro, EstiloPaginacion, EstiloFooter, EstiloInfo, ListUL, Green, Gray, Black, Blue}
