import React from 'react';
import { EstiloFooter } from './styles.js';

const Footer = () => {


    return (
     <EstiloFooter>
      <div>
        <h3>Universidades Compran</h3>
        <p>Desarrollado por <a href='http://www.siu.edu.ar' rel="noopener noreferrer" target='_blank'>SIU</a> - <a href='http://www.cin.edu.ar' rel="noopener noreferrer" target='_blank'>CIN</a></p>
     </div>

     </EstiloFooter>

    )
}

export default Footer
