import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Home';
import RedirectTo from './RedirectTo';
import Info from './Info';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>

          <Switch>
            <Route
              path="/redirect/:urlPortal"
              component={RedirectTo} />
            <Route
              path="/info"
              component={Info} />
            <Route
              path="/"
              component={Home} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}
export default App;
