import React from 'react'
import { Col, Form } from "react-bootstrap";
import { EstiloFiltro } from './styles.js';

const TablaFilter = ({headerGroups, onChange, rubros} ) => {
  return (
      <EstiloFiltro>
        <Form>
          <Form.Row>
            <Form.Group as={Col} sm={12} md={6} lg={3} controlId="formRubros">
              <span>Rubro</span>
              <select onChange={onChange}>
                <option value="all">Todos</option>
                {rubros.map(rubro => (<option key={rubro.nombre}>{rubro.nombre}</option>))};
              </select>
            </Form.Group>

            {headerGroups.map(headerGroup => (
              <>
                {headerGroup.headers.map(column => (
                  column.canFilter === true ?
                  <Form.Group as={Col} sm={12} md={6} lg={3} {...column.getHeaderProps()}> 
                    <span>{column.canFilter ? column.render('Header') : null}</span>
                    {column.canFilter ? column.render('Filter') : null}
                  </Form.Group>
                  : ''
                ))}
              </>
            ))}
          </Form.Row>
        </Form>
      </EstiloFiltro>
  );
};

export default TablaFilter
