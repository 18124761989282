export const convocatoriaService = {
    getAll,
    getAllConFiltro,
    getRubros,
    getUniversidades,
    getInfo
};

const url = window._env_.API_URL;

const requestOptions = {
    method: 'GET',
};

function getAll() {
     return fetch(url + 'frontend/convocatorias?order=%2Bfecha_fin', requestOptions).then(handleResponse);
}

function getAllConFiltro(rubro) {

    const filtro = 'rubro=contiene;' + rubro;

    return fetch(url + 'frontend/convocatorias?order=%2Bfecha_fin&' + filtro, requestOptions).then(handleResponse);
}

function getRubros() {
    return fetch(url + 'frontend/rubros', requestOptions).then(handleResponse);
}

function getUniversidades() {
    return fetch(url + 'frontend/universidades?order=%2Bsigla', requestOptions).then(handleResponse);
}

function getInfo(){
    return fetch(url + 'frontend/info', requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if(response.status !== 200){
      return [];
    }

    return response.text().then(text => {

        const data = text && JSON.parse(text);
        if (!response.ok) {

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleUltimaActualizacion(response) {
    if(response.status !== 200){
      return [];
    }
    return response.text().then(text => {
        return response.headers.get('ultima-actualizacion');
    });
}
