import React, { Component } from 'react';
import { convocatoriaService } from './_services/convocatorias.services.js';
import TablaConvocatorias from './components/tablaConvocatorias.js';
import Header from './components/header.js';
import Footer from './components/footer.js';
import ListUniversidades from './components/listUniversidades.js';

class Home extends Component {
  

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      convocatorias: [],
      universidades: [],
      rubros: [],
      info: [],
      filtro: 'filtro',
    }
    this.handleChangeRubro = this.handleChangeRubro.bind(this);

    this.updateFiltro = this.updateFiltro

  }
  updateFiltro = (filtro) => {this.setState({ filtro })}

  componentDidMount() {
      window.gtag('config', 'G-NPF1TPK6EJ', { 'page_title': document.title, 'page_path': window.location.pathname + window.location.search });

      convocatoriaService.getAll().then(convocatorias => this.setState({ convocatorias }));
      convocatoriaService.getRubros().then(rubros => this.setState({ rubros }));
      convocatoriaService.getUniversidades().then(universidades => this.setState({ universidades }));
      convocatoriaService.getInfo().then(info => this.setState({ info }));

  }

  handleChangeRubro(event) {
    if(event.target.value !== 'all'){
      convocatoriaService.getAllConFiltro(event.target.value).then(convocatorias => this.setState({ convocatorias }));
    }else{
      convocatoriaService.getAll().then(convocatorias => this.setState({ convocatorias }));
    }
  }

  render() {

    return (
      <>
        <Header />
        <div>
          <TablaConvocatorias
            convocatorias={this.state.convocatorias}
            rubros={this.state.rubros}
            info={this.state.info}
            handleChangeRubro={this.handleChangeRubro}
          />
        </div>
        <ListUniversidades universidades={this.state.universidades} />
        <Footer />
      </>
    );
  }

}

export default Home;
