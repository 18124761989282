import React, { Component } from 'react';
import Header from './components/header.js';
import Footer from './components/footer.js';
import { EstiloInfo } from './components/styles.js';


class Info extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    window.gtag('config', 'G-NPF1TPK6EJ', { 'page_title': document.title, 'page_path': window.location.pathname + window.location.search });
  }



  render() {

    return (
      <>
        <Header />
        <EstiloInfo>
        <h3>¿Qué es Universidades Compran?</h3>
        <p>El Portal Universidades Compran es una plataforma que consolida los procedimientos de compras de bienes y servicios vigentes en las Universidades Nacionales.</p>
        <p>Este portal concentra la información existente en aquellas instituciones que utilizan el Portal de Compras Públicas del Módulo de Compras, Contrataciones y Patrimonio SIU-Diaguita. <b>Es decir, solo muestra la información publicada en estos portales.</b></p>

        <h3>¿Cuál es el objetivo de este portal?</h3>
        <p>Este Portal se propone generar un espacio de difusión de información relacionada a los procedimientos de compras de las universidades nacionales, colaborando con la transparencia, legalidad y legitimidad de los procesos y la igualdad de condiciones de los interesados.</p>
        
        <h3>¿Cómo funciona este Portal?</h3>
        <p>Este portal toma la información básica que se encuentra publicada en los Portales de Compras Públicas SIU-Diaguita de las Universidades que lo utilizan. Permite visualizar la misma y acceder al procedimiento de compra que sea de interés a través de un enlace directo al Portal que la contenga.</p>
        
        <h3>¿Cómo se actualiza la información?</h3>
        <p>Este portal actualiza la información de forma automatizada. Se puede verificar la fecha y hora de la última actualización de datos desde el listado de procedimientos.</p>

        <h3>¿Cómo obtengo más información sobre el procedimiento de compra?</h3>
        <p>Para conocer los detalles específicos de las licitaciones, descargar documentación y realizar consultas y seguimientos del proceso, deberá ingresar al Portal de Compras Públicas de la Universidad que lleva adelante la misma.</p>
        <p>Podrá acceder al detalle del proceso de compra desde el enlace (icono de lupa) que se encuentra en el listado de procedimientos en la página principal del Portal.</p>

        </EstiloInfo>
        <Footer />
      </>
    );
  }

}

export default Info;
