import React from 'react'
import { useTable, usePagination, useSortBy, useFilters } from 'react-table';
import Table from 'react-bootstrap/Table';
import TablaFilter from './filter.js';
import UltimaActualizacion from './ultimaActualizacion.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faCalendarPlus, faCaretSquareDown, faCaretSquareUp } from '@fortawesome/free-regular-svg-icons'
import { faSearch, faAngleRight,faAngleDoubleRight, faAngleLeft, faAngleDoubleLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Styles, EstiloTabla, EstiloFiltro, EstiloPaginacion, Green, Gray, Black } from './styles.js';
import ShowMoreText from 'react-show-more-text';

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    //const count = preFilteredRows.length

    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Ingrese el texto a buscar`}
      />
    )
  }

  function ManualColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
  }) {
    return (
      <input
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    );
  }

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">Todos</option>
        {options.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
  }

  function filterExactMatch(rows, id, filterValue) {
    return rows.filter(row => {
      const rowValue = row.values[id]
      return rowValue === filterValue
    })
  }

  function MiTabla({ columns, data, rubros, info, handleChangeRubro, filters, setFilters  }) {

    const filterTypes = React.useMemo(
       () => ({

         text: (rows, id, filterValue) => {
           return rows.filter(row => {
             const rowValue = row.values[id]
             return rowValue !== undefined
               ? String(rowValue)
                   .toLowerCase()
                   .startsWith(String(filterValue).toLowerCase())
               : true
           })
         },
       }),
       []
     )

     const defaultColumn = React.useMemo(
       () => ({
         Filter: DefaultColumnFilter,
       }),
       []
     )

     const manualColumn = React.useMemo(
       () => ({
         Filter: ManualColumnFilter,
       }),
       []
     )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      //rows,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      //state,
      state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        defaultColumn,
        manualColumn,
        manualFilters: false,
        filterTypes,
        initialState: { pageIndex: 0 },
      },
      useFilters,
      useSortBy,
      usePagination
    )

    const onChangeRubro = (e) => {
      console.log(filters);
        handleChangeRubro(e);
    };

    return (
      <>

      <EstiloFiltro>
        <TablaFilter headerGroups={headerGroups} onChange={onChangeRubro} rubros={rubros} />
      </EstiloFiltro>

      <EstiloTabla>
        <Table class="listado" responsive striped bordered hover size="sm" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps({ title: "Haga click para cambiar el ordenamiento" }))}>
                    {column.render('Header')}
                    {/* sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <FontAwesomeIcon icon={faCaretSquareDown} />
                          : <FontAwesomeIcon icon={faCaretSquareUp} />
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>

      <UltimaActualizacion info={info}/>

      </EstiloTabla>

      <EstiloPaginacion>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>{' '}

          <span>
            P&aacute;gina{' '}
            <input
              type="text"
              value={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
            de {pageOptions.length}
          </span>{' '}

          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <FontAwesomeIcon icon={faAngleRight} />
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </button>{' '}

          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Ver {pageSize}
              </option>
            ))}
          </select>
        </div>
      </EstiloPaginacion>
      </>

    )
  }

function redirectTo(urlPortal) {
  let url = encodeURIComponent(urlPortal);
  window.open('/redirect/'+url, "_blank")
}

  const TablaConvocatorias = ({ convocatorias, rubros, info, handleChangeRubro}) => {

    const columns = React.useMemo(
      () => [
            {
              Header: "Universidad",
              accessor: "universidad_sigla",
              id: "universidad_sigla",
              Filter: SelectColumnFilter,
              filter: filterExactMatch,
              width: '100'
            },
            {
              Header: "Nro",
              accessor: "numero",
              id: "numero",
              disableFilters: true,
              width: '100'
            },
            {
              Header: "Tipo de procedimiento",
              accessor: "tipo_procedimiento",
              id: "tipo_procedimiento",
              Filter: SelectColumnFilter,
              width: 200
            },
            {
              Header: "Objeto",
              accessor: "objeto",
              id: "objeto",
              Filter: ManualColumnFilter,
              style: { 'whiteSpace': 'normal' },
              disableSortBy: true,
              minWidth: 400,
              Cell: row => <ShowMoreText lines={1} more='Ver más' less='Ver menos' anchorClass='' expanded={false} width={600}> {row.value}</ShowMoreText>
            },
            {
              Header: "Inicio",
              accessor: row => new Date(row.fecha_inicio_timestamp * 1000),
              id: "fecha_inicio",
              sortType: 'datetime',
              disableFilters: true,
              width: 100,
              Cell: ({ cell: { value }}) => value.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit'})
            },
            {
              Header: <> Fin <FontAwesomeIcon title="Pueden existir circulares modificatorias que amplíen la fecha" icon={faInfoCircle} size="xs" /></>,
              accessor: row => new Date(row.fecha_fin_timestamp * 1000),
              id: "fecha_fin",
              sortType: 'basic',
              disableFilters: true,
              width: 100,
              Cell: ({ cell: { value }}) => value.toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'})
            },
            {
              Header: "",
              accessor: "fecha_inicio_timestamp",
              id: "vigente_proxima",
              disableSortBy: true,
              disableFilters: true,
              Cell: ({ value }) => (value < Math.floor( new Date().getTime()/1000) ? <Green><FontAwesomeIcon title="Vigente" icon={faCalendarCheck} /></Green> : <Gray><FontAwesomeIcon title ="Apertura pr&oacute;xima" icon={faCalendarPlus} /></Gray> ),
            },
            {
              Header: "",
              accessor: "url_detalle",
              id: "detalle",
              disableFilters: true,
              disableSortBy: true,
              width: 100,
              Cell: row =><a target="_blank" rel="noopener noreferrer" onClick={(e) => redirectTo(row.value, e)} title= "Detalle de la convocatoria"> <Black><FontAwesomeIcon icon={faSearch} /></Black> </a>
            }
          ]
    );
    //const [filters, setFilters] = React.useState([]);


    return (
        <Styles>
          <MiTabla columns={columns} data={convocatorias} rubros={rubros} info={info} handleChangeRubro={handleChangeRubro}/>
        </Styles>
    );
  };

  export default TablaConvocatorias
