import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const UltimaActualizacion = ({info} ) => {

  const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Hubo un error al obtener los datos de: {info.universidades_error}
  </Tooltip>
  );

  return (

      <p class="fecha_actualizacion">
          Ultima actualizaci&oacute;n de datos: {info.ultima_actualizacion}&nbsp;
          {info.error_actualizacion  ? 
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
              >
                <FontAwesomeIcon variant="success" icon={faInfoCircle} />
              </OverlayTrigger> : '' 
            }
      </p>

  );
};

export default UltimaActualizacion
