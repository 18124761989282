import React from 'react';
import { EstiloHeader } from './styles.js';

const Header = () => {

//<h3>Universidades Compran</h3>
    return (
     <EstiloHeader>
        <div>
          <div class="logo">
           <a href="/"> <img src="logo.png" alt="Logo Universidades Compran"/></a>
          </div>

         <ul class="menu">
           <li><a href="/info">Acerca de</a></li>
         </ul>
        </div>
     </EstiloHeader>

    )
}

export default Header
