import React from 'react';
import { ListUL } from './styles.js';

const ListUniversidades = ({universidades}) => {


    return (

      <>
        <ListUL>
        <h3>Las siguientes universidades forman parte de Universidades Compran:</h3>
        <ul>
          {universidades.map(universidad => (<li key={universidad.id}> <a title={universidad.nombre} href={universidad.url_portal} rel="noopener noreferrer" target='_blank'> {universidad.sigla} </a></li>))}
        </ul>
        </ListUL>
      </>
    )
}

export default ListUniversidades
