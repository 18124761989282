import React, { Component } from 'react';

class RedirectTo extends Component {

  render() {
    console.log(this.props.match.params.urlPortal);

    setTimeout(() => {
      window.location.href = decodeURIComponent(this.props.match.params.urlPortal);
    }, 2000);

    return (
      <p class="redirect-msg">Usted ser&aacute; redirigido al Portal de Compras P&uacute;blicas de la Universidad</p>

    );
  }
}
export default RedirectTo;
